import React, { useEffect, useState } from 'react';

export interface ComparisonProps {
  mySummary: ComparisonUser;
  userSummary: ComparisonUser;
}

export type ComparisonUser = {
  id: string;
  name: string;
  image: string;
  place: number;
  total_points: number;
  exercises: ComparisonExercise[];
};

export type ComparisonExercise = {
  amount: number;
  title: string;
  measurement: string;
  measurementType: string;
  summary: string;
};

export type ComparisonUnion = {
  exerciseTitle: string;
  myExerciseSummary: string;
  userExerciseSummary: string;
  myExerciseAmount: number;
  userExerciseAmount: number;
};

const Comparison: React.FC<ComparisonProps> = ({ mySummary, userSummary }: ComparisonProps) => {
  const [close, setClose] = useState(false);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setClose(true);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  function returnComparisonUnion(): ComparisonUnion[] {
    // Find common titles with at least one amount greater than 0
    const commonTitlesWithAmountGreaterThanZero = mySummary.exercises.filter((item1) => {
      const matchingItem = userSummary.exercises.find(
        (item2) => item2.title === item1.title && (item1.amount > 0 || item2.amount > 0),
      );
      return matchingItem !== undefined;
    });

    // Create a list with title and both values of amount
    return commonTitlesWithAmountGreaterThanZero.map((commonItem) => {
      const item1 = mySummary.exercises.find((item) => item.title === commonItem.title);
      const item2 = userSummary.exercises.find((item) => item.title === commonItem.title);
      return {
        exerciseTitle: commonItem.title,
        myExerciseSummary: item1!!.summary,
        userExerciseSummary: item2!!.summary,
        myExerciseAmount: item1!!.amount,
        userExerciseAmount: item2!!.amount,
      } as ComparisonUnion;
    });
  }

  return (
    <div className={close ? 'hidden' : ''}>
      <div
        onClick={() => setClose((value) => !value)}
        className="fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"></div>
      <div className="overflow-scroll	max-h-3xl fixed left-[50%] top-[50%] z-50 w-full max-w-3xl translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg">
        <button
          onClick={() => setClose(true)}
          type="button"
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-4 w-4">
            <line x1="18" x2="6" y1="6" y2="18"></line>
            <line x1="6" x2="18" y1="6" y2="18"></line>
          </svg>
          <span className="sr-only">Close</span>
        </button>
        <div className="flex flex-row space-x-2 mt-2">
          <table className="w-full caption-bottom text-sm">
            <thead className="[&amp;_tr]:border-b">
              <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                <th className="h-18 px-4 text-right align-middle font-medium"></th>
                <th className="h-18 px-4 text-right align-middle font-medium">
                  <div className="flex flex-row justify-end py-4">
                    <div className="mr-2">
                      <img className="inline-block w-8 h-8 rounded-full" src={mySummary.image} alt={mySummary.name} />
                    </div>
                    <div className="mt-2"> #{mySummary.place} You</div>
                  </div>
                </th>
                <th className="h-18 px-4 text-right align-middle font-medium">
                  <div className="flex flex-row justify-end py-4">
                    <div className="mr-2">
                      <img
                        className="inline-block w-8 h-8 rounded-full"
                        src={userSummary.image}
                        alt={userSummary.name}
                      />
                    </div>
                    <div className="mt-2">
                      #{userSummary.place} {userSummary.name}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="[&amp;_tr:last-child]:border-0">
              {returnComparisonUnion().map((item) => (
                <tr
                  key={item.exerciseTitle}
                  className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                  <td className="h-8 px-4 text-right align-middle">{item.exerciseTitle}</td>
                  <td className="h-8 px-4 text-right">
                    <div className="flex flex-row justify-end">
                      {item.myExerciseAmount === 0 ? '-' : item.myExerciseSummary}
                      {item.myExerciseAmount > item.userExerciseAmount && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 ml-1">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                        </svg>
                      )}
                    </div>
                  </td>
                  <td className="h-8 px-4 text-right align-middle">
                    <div className="flex flex-row justify-end">
                      {item.userExerciseAmount === 0 ? '-' : item.userExerciseSummary}
                      {item.userExerciseAmount > item.myExerciseAmount && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 ml-1">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                        </svg>
                      )}
                    </div>
                  </td>
                </tr>
              ))}

              <tr
                key={Math.random()}
                className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                <td className="h-8 px-4 text-right align-middle">Total Points:</td>
                <td className="h-8 px-4 text-right">
                  <div className="flex flex-row justify-end font-semibold">
                    {mySummary.total_points}
                    {parseFloat(mySummary.total_points) > parseFloat(userSummary.total_points) && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4 ml-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )}
                  </div>
                </td>
                <td className="h-8 px-4 py-4 text-right align-middle">
                  <div className="flex flex-row justify-end font-semibold">
                    {userSummary.total_points}
                    {parseFloat(userSummary.total_points) > parseFloat(mySummary.total_points) && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4 ml-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Comparison;
